import React, { useState, useEffect, createContext } from "react";

import { Button, message, Tag, Modal, Input } from "antd";
import {} from "./GiftExchange.style";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import * as queryString from "query-string";
import { useHistory } from "react-router-dom";
import thank from "../../../image/gift-thank2.png";
import text from "../../../image/gift-text.png";
import text2 from "../../../image/gift-text2.png";
// import bg_mb from "../../../image/bg_mb.svg";
import { CUSTOMER_COMMENT } from "../../../graphql/MUTATION/customer";
import { useMutation } from "@apollo/react-hooks";
import { Container } from "./GiftExchange.style";
import $ from "jquery";
import ReactGA from "react-ga";

const { confirm } = Modal;
const { TextArea } = Input;

function initzeAnalytics() {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
}

const Thanks = () => {
  const [voteDone, setVoteDone] = useState(false);
  const [createComment] = useMutation(CUSTOMER_COMMENT);
  const parameters = queryString.parse(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const { eventId } = parameters;
  const history = useHistory();

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  const getScreenType = () => {
    if (window.innerHeight > window.innerWidth) {
      return "vertical";
    }
    return "horizontal";
  };

  const DeviceType = getDeviceType();
  const ScreenType = getScreenType();

  useEffect(() => {
    initzeAnalytics();
    $("body").css("overflow", "hidden");
  }, []);

  useEffect(() => {
    ReactGA.pageview(history?.location?.pathname);
  }, [history]);

  return (
    <>
      <Container style={{}}>
        <div className="image-wrapper">
          <img className="image" src={text} />
          <img className="image center" src={thank} />
          <img className="image bottom" src={text2} />
        </div>
      </Container>
    </>
  );
};

export default Thanks;
