import React from 'react';

const Wrapper = (props) => {
  return (
    <div
      style={{
        borderLeft: '1px solid #efefef',
        borderRight: '1px solid #efefef',
        borderBottom: !props.flagBorder ? '1px solid #efefef' : '',
        // marginTop: '70px',
      }}
    >
      {props.children}
    </div>
  );
};
const CategoryName = (props) => {
  return (
    <div
      style={{
        backgroundColor:
          props?.color === 'RED'
            ? '#e84563'
            : props?.color === 'GREEN'
              ? '#67cc59'
              : '#558ddf',
        padding: '10px',
        fontWeight: 700,
        fontFamily: 'inherit',
        marginBottom: '10px',
        color: '#ffffff',
        fontSize: '20px',
        height: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {props.children}
    </div>
  );
};
const MakerName = (props) => {
  return (
    <div
      style={{
        textTransform: 'uppercase',
        color: '#376092',
        fontWeight: '700',
        fontSize: '16px',
        fontFamily: 'inherit',
        // borderBottom: '1px solid #efefef',
        width: '100%',
        display: 'flex',
      }}
    >
      {props.children}
    </div>
  );
};

export { Wrapper, CategoryName, MakerName };
