import React, { useEffect, useState, useContext } from "react";
import MakerConText from "../../context/makerContext";
import { useSelector } from "react-redux";
import { Select } from "antd";
const { Option } = Select;

/* eslint-disable */
const HeaderMobile = (props) => {
  const { makerById, loading, eventById, deviceType } = props;
  const [onProcess, setOnProcess] = useState(eventById.eventById?.onProcess);
  const [defaultValue, setDefaultValue] = useState("");
  const { makerId } = useContext(MakerConText);
  const [companyName, setCompanyName] = useState("");

  const { currentCategory } = useSelector((state) => state.loading);
  useEffect(() => {
    setOnProcess(eventById.eventById?.onProcess);
  }, [eventById.eventById.onProcess]);

  let guestInfo = JSON.parse(localStorage.getItem("guestInfo"));
  useEffect(() => {
    if (guestInfo) {
      setCompanyName(guestInfo?.companyName || guestInfo?.name);
    }
  });

  const listMaker = [];
  const _makerById =
    makerById?.makerByEventId?.filter(
      (maker) => maker?.categories[0]?.code === currentCategory
    ) || [];
  if (_makerById.length) {
    listMaker.push(
      <Option key={"default"} value="">
        すべてのメーカー
      </Option>
    );
    for (let i = 0; i < _makerById.length; i++) {
      listMaker.push(
        <Option key={i} value={_makerById[i].id}>
          {_makerById[i].name}
        </Option>
      );
    }
  }
  return (
    <>
      <div
        style={{
          transition: "0.3s",
          backgroundColor: "rgb(57, 62, 65)",
          width: "100%",
          paddingBottom: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              flex: "1",
              marginTop: deviceType === "tablet" ? "5px" : "8px",
            }}
          >
            <div
              style={{
                flex: "1",
                fontSize: "16px",
                marginLeft: "3px",
                fontWeight: 700,
                color: "#FFFFFF",
              }}
            >
              いいね！ 投票
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#FFFFFF",
                }}
              >
                SINCE
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#ff0000",
                  marginLeft: "3px",
                }}
              >
                2020.11
              </div>
            </div>
          </div>
          {deviceType === "tablet" ? (
            <>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    flex: "1",
                    fontWeight: 700,
                    color: "#FFFFFF",
                    fontSize: "2rem",
                    marginTop: "4px",
                  }}
                >
                  投票商品検索
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#096dd9",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  メーカーの選択
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <MakerConText.Consumer>
                    {({ addMaker }) => (
                      <Select
                        style={{ width: "200px", zIndex: 100 }}
                        defaultValue={defaultValue}
                        value={makerId}
                        onChange={addMaker}
                      >
                        {listMaker}
                      </Select>
                    )}
                  </MakerConText.Consumer>
                </div>
              </div>
            </>
          ) : (
            <div style={{ flex: "1" }}>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: 700,
                  color: "#FFFFFF",
                }}
              >
                投票商品検索
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "#096dd9",
                  justifyContent: "center",
                }}
              >
                <MakerConText.Consumer>
                  {({ addMaker }) => (
                    <Select
                      style={{ width: 150, zIndex: 100 }}
                      // defaultValue={defaultValue}
                      value={makerId}
                      placeholder="メーカーの選択"
                      onChange={addMaker}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {listMaker}
                    </Select>
                  )}
                </MakerConText.Consumer>
              </div>
            </div>
          )}
        </div>
        {eventById?.eventById?.id ===
          process.env.REACT_APP_CHUBU_CUSTOMER_VOTE_EVENT && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "5px 0 0 0",
              color: "white",
              fontSize: "16px",
              justifyContent: "center",
            }}
          >
            <span>{companyName}</span>
            {!guestInfo?.companyName && (
              <span style={{ fontSize: "11px", marginTop: "5px" }}>様</span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderMobile;
