import { useQuery } from "@apollo/react-hooks";
import { Col, Row, Spin } from "antd";

import React, { useContext, useEffect, useState } from "react";
import ProductCard from "../../../components/body/Product/ProductCardMobile";
import MakerConText from "../../../context/makerContext";
import { QUERY_PRODUCT_BY_EVENT_AND_CATEGORY } from "../../../graphql/QUERY/product";
import { MakerNameMB } from "../../body/Body.style";
import "./category.css";
import { useSelector } from "react-redux";
import { isMobileSafari } from "react-device-detect";

const Category = (props) => {
  const { makerId } = useContext(MakerConText);
  const { color, makerById, eventById, deviceType, setVoted } = props;
  const {
    data: productByEventAndCategory,
    refetch: refetchProductByEventAndCategory,
    error,
    loading: isLoading,
  } = useQuery(QUERY_PRODUCT_BY_EVENT_AND_CATEGORY, {
    fetchPolicy: "no-cache",
    variables: {
      eventId: eventById?.eventById?.id || "no-event",
      categoryCode: color,
    },
  });
  const [makerByCate, setMakerByCate] = useState([]);
  const [newMakerAndProduct, setNewMakerAndProduct] = useState([]);
  const { currentCategory } = useSelector((state) => state.loading);
  useEffect(() => {
    let makerByCategory = [];
    if (makerId) {
      const chooseMaker = makerById?.makerByEventId?.filter(
        (item) => item?.id === makerId
      );
      makerByCategory = chooseMaker?.filter(
        (item) => item?.categories[0].code === color
      );
    } else {
      makerByCategory = makerById?.makerByEventId?.filter(
        (item) => item?.categories[0].code === color
      );
    }
    if (makerByCategory) {
      makerByCategory.sort((a, b) => a.makerPosition - b.makerPosition);
    }
    setMakerByCate(makerByCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makerById, makerId]);

  useEffect(() => {
    if (makerByCate) {
      let _newMakerAndProduct = [];
      refetchProductByEventAndCategory().then(({ data }) => {
        const newList = makerByCate.map((item) => ({
          ...item,
          products: data.productByEventAndCategory.filter(
            (product) => product.maker.id === item.id
          ),
        }));

        _newMakerAndProduct = newList.filter(
          (product) => product.products.length > 0
        );

        setNewMakerAndProduct(_newMakerAndProduct);
      });
    }
  }, [
    makerByCate,
    productByEventAndCategory,
    currentCategory,
    refetchProductByEventAndCategory,
  ]);
  if (error || !newMakerAndProduct?.length) {
    if (isLoading)
      return (
        <div className="example">
          <Spin />
        </div>
      );
    return <div className="noResult">イベントに商品がありません。</div>;
  } else {
    return (
      <div
        style={{
          height: isMobileSafari ? "89vh" : "86vh",
          marginBottom: 30,
          overflowY: "overlay",
          overflowX: "hidden",
          paddingBottom:
            window.innerWidth < 300 ? 300 : window.innerWidth < 330 ? 280 : 250,
        }}
      >
        {newMakerAndProduct?.map((maker, idx) => {
          return (
            <>
              <MakerNameMB deviceType={deviceType}>
                {maker.name || ""}
              </MakerNameMB>
              <Row
                key={JSON.stringify(maker)}
                justify="left"
                style={{
                  width: "100%",
                }}
                gutter={8}
                span={24}
              >
                {maker?.products
                  ?.sort((a, b) => (b.position > a.position ? -1 : 1))
                  .map((prod, idx) => {
                    return (
                      <Col key={idx} style={{ paddingTop: 10 }} xl={8} span={8}>
                        <ProductCard
                          deviceType={deviceType}
                          title={prod?.name}
                          description={prod?.description}
                          urlImage={prod?.image}
                          data={prod}
                          color={color}
                          maker={maker.name}
                          onProcess={eventById.eventById.onProcess}
                          eventId={eventById?.eventById}
                          setVoted={setVoted}
                        />
                      </Col>
                    );
                  })}
              </Row>
            </>
          );
        })}
      </div>
    );
  }
};
export default Category;
