import React, { Component } from 'react';

import MakerContext from '../context/makerContext';

export default class MakerProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      makerId: '',
      categoryCode: '',
    };

    this.addMaker = this.addMaker.bind(this);
    this.addCategory = this.addCategory.bind(this);
  }

  addMaker(maker) {
    this.setState({
      makerId: maker,
    });
  }
  addCategory(category) {
    this.setState({
      categoryCode: category,
    });
  }
  render() {
    return (
      <MakerContext.Provider
        value={{
          makerId: this.state.makerId,
          categoryCode: this.state.categoryCode,
          addMaker: this.addMaker,
          addCategory: this.addCategory,
        }}
      >
        {this.props.children}
      </MakerContext.Provider>
    );
  }
}
