import React from "react";
import LazyLoad from "react-lazy-load";

const WrapperContent = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        // width: window.innerWidth / 3,
        width: props.deviceType === "tablet" ? "32vw" : "30.5vw",
        flex: 1,
        margin: props.deviceType === "tablet" ? "5px 5px 5px 7px" : "5px",
        padding: "4px",
        border: "1px solid #efefef",
        backgroundColor: "#ffffff",
      }}
    >
      {props.children}
    </div>
  );
};

const WrapperImage = (props) => {
  return (
    <div
      style={{
        height: props.deviceType === "tablet" ? "150px" : "100px",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LazyLoad offsetVertical={100}>
        <img
          style={{
            paddingTop: 5,
            maxWidth: "100%",
            maxHeight: "100%",
            display: "inline-block",
          }}
          alt="example"
          src={props.urlImage}
        />
      </LazyLoad>
    </div>
  );
};

const ProductName = (props) => {
  return (
    <div
      style={{
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Lato, sans-serif",
        color: "#0d2830",
        textAlign: "left",
        // borderBottom: onProcess ? '1px solid #efefef' : '',
        // color: '#262626',
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        overflow: "hidden",
        marginLeft: props.deviceType === "tablet" ? "5px" : "0px",
      }}
    >
      {props.children}
    </div>
  );
};

const Description = (props) => {
  return (
    <div
      style={{
        fontSize: "15px",
        textAlign: "left",
        fontFamily: "Lato,sans-serif",
        color: "#77798c",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        marginLeft: props.deviceType === "tablet" ? "5px" : "0px",
        overflow: "hidden",
        // borderBottom: props.onProcess ? '1px solid #efefef' : '',
      }}
    >
      {props.children}
    </div>
  );
};

const WrapButton = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {props.children}
    </div>
  );
};

const ImageModal = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
      <img
        style={{
          paddingTop: 5,
          maxHeight: props.deviceType === "tablet" ? "100%" : "40vh",
          width: "70vw",
          // maxWidth: props.deviceType === 'tablet' ? '100%' : '60%',
          // maxHeight: props.deviceType === 'tablet' ? '100%' : '60%',
          display: "inline-block",
        }}
        alt="example"
        src={props.urlImage}
      />
    </div>
  );
};

const ProductNameModal = (props) => {
  return (
    <div
      style={{
        fontSize: props.deviceType === "tablet" ? "25px" : "20px",
        fontWeight: "700",
        fontFamily: "Lato, sans-serif",
        color: "#0d2830",
        textAlign: "left",
        marginLeft: "10px",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {props.children}
    </div>
  );
};

const DescriptionModal = (props) => {
  return (
    <div
      style={{
        fontSize: props.deviceType === "tablet" ? "18px" : "14px",
        textAlign: "left",
        fontFamily: "Lato,sans-serif",
        color: "#77798c",
        width: "100%",
        overflow: "hidden",
        marginLeft: "10px",
        paddingRight: "10px",
      }}
    >
      {props.children}
    </div>
  );
};

const MakerModal = (props) => {
  return (
    <div
      style={{
        textTransform: "uppercase",
        color: "#376092",
        fontWeight: "600",
        fontSize: props.deviceType === "tablet" ? "20px" : "16px",
        fontFamily: "inherit",
        width: "100%",
        marginLeft: "10px",
        paddingRight: "10px",
        marginTop: "5px",
      }}
    >
      {props.children}
    </div>
  );
};
const CategoryModal = (props) => {
  return (
    <div
      style={{
        fontSize: props.deviceType === "tablet" ? "20px" : "14px",
        textAlign: "left",
        fontFamily: "Lato,sans-serif",
        color: "#77798c",
        width: "100%",
        overflow: "hidden",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "10px",
        marginTop: "5px",
        borderBottom: "1px solid #efefef",
      }}
    >
      {props.children}
    </div>
  );
};

export {
  CategoryModal,
  MakerModal,
  DescriptionModal,
  ProductNameModal,
  ImageModal,
  WrapperContent,
  ProductName,
  Description,
  WrapButton,
  WrapperImage,
};
