import gql from "graphql-tag";

const CUSTOMER_COMMENT = gql`
  mutation ($commentEventInput: CommentEventInput!) {
    customerComment(commentEventInput: $commentEventInput) {
      customer {
        id
      }
      event {
        id
        name
      }
    }
  }
`;

export { CUSTOMER_COMMENT };
