import styled from "styled-components";

export const VoteCard = styled.div`
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  border: 1px solid #4f81bd;
  transition: 0.3s;
  background: #fff;
  float: left;
  height: 100%;
  width: 100%;
  cursor: pointer;
  padding: 5px;
  .card-title {
    font-size: 32px;
    font-weight: 700;
    font-family: "Lato; sans-serif";
    color: #0d2830;
    padding-right: 10px;
    text-align: left;
    margin-left: 10px;
    // textOverflow: ellipsis;
    // whiteSpace: nowrap;
    width: 100%;
    overflow: hidden;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
`;
