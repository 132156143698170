import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { ApolloProvider } from '@apollo/react-hooks';
// import ApolloClient from 'apollo-boost';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import MakerProvider from '../src/context/makerProvider';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './redux/index'
import client from './apollo-config'
// const client = new ApolloClient({
//   fetchOptions: { fetch },
//   uri: process.env.REACT_APP_API_URL,
//   request: (operation) => {
//     const token = localStorage.getItem('access_token');
//     operation.setContext({
//       headers: {
//         authorization: token ? `Bearer ${token}` : '',
//       },
//     });
//   },
// });

ReactDOM.render(
  <Provider store={store}>

    <ApolloProvider client={client}>
      <MakerProvider>
        <React.StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      </MakerProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
 