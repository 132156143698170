import React from "react";
import { Bar } from "react-chartjs-2";
import { Result } from "antd";
import {
  Wrapper,
  WrapperNameCategory,
  NameCategory,
  Label,
  NoResult,
} from "./Custom";
import categories_colors from "../../constants/categories-color";

const BlueBarChart = ({ Blue, titleName, ...props }) => {
  const dataByCategoryBlue = Blue?.productByEventAndCategory
    .map((item) => {
      return {
        name: item.graphDisplayName || item.name,
        vote: item.vote,
        qrVote: item.qrVote,
        total: parseInt(item.vote) + parseInt(item.qrVote),
      };
    })
    .sort((a, b) => b.total - a.total)
    .slice(0, 30);

  const dataBlue = {
    labels: dataByCategoryBlue?.map((item) => {
      return item.name;
    }),
    datasets: [
      {
        label: "リアル投票",
        borderWidth: 1,
        backgroundColor: categories_colors[4].hex_primary,
        hoverBackgroundColor: categories_colors[4].hex_secondary,
        data: dataByCategoryBlue?.map((item) => {
          return item.qrVote;
        }),
      },
      {
        label: "WEB投票",
        backgroundColor: categories_colors[2].hex_primary,
        hoverBackgroundColor: categories_colors[2].hex_secondary,
        borderWidth: 1,
        data: dataByCategoryBlue?.map((item) => {
          return item.vote;
        }),
      },
    ],
  };

  return (
    <Wrapper>
      <WrapperNameCategory>
        <NameCategory>いいね！投票：{titleName ? titleName : ""}</NameCategory>
        <Label color={categories_colors[2].hex_primary} />
      </WrapperNameCategory>
      {Blue?.productByEventAndCategory?.length === 0 ? (
        <NoResult>
          <Result title="データがありません" />
        </NoResult>
      ) : (
        <Bar data={dataBlue} options={options} />
      )}
    </Wrapper>
  );
};
const options = {
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          min: 0,
          beginAtZero: 0,
          callback: (value) => {
            if (typeof value !== "string") {
              return "グラフ表示名".replaceAll(/[ ,ー,一,－]/g, "|").split("");
            } else {
              if (value.length > 5) {
                return value
                  .slice(0, 20)
                  .replaceAll(/[ ,ー,一,－]/g, "|")
                  .split("");
              } else {
                return value.replaceAll(/[ ,ー,一,－]/g, "|").split("");
              }
            }
          },
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return value === Math.round(value) ? value : null;
          },
        },
        scaleLabel: {
          display: true,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: false,
      color: "white",
    },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem) {
        return tooltipItem.yLabel;
      },
    },
  },
};

export default BlueBarChart;
