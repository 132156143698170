import { useQuery } from "@apollo/react-hooks";
import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import MakerConText from "../../../context/makerContext";
import { QUERY_PRODUCT_BY_EVENT_AND_CATEGORY } from "../../../graphql/QUERY/product";
import { CategoryName, MakerName, Wrapper } from "../Category/Category.style";
import ProductCard from "../Product/ProductCard";

const Category = (props) => {
  const isIE =
    navigator.userAgent.indexOf("MSIE ") > -1 ||
    navigator.userAgent.indexOf("Trident/") > -1;
  const { makerId } = useContext(MakerConText);
  const { color, makerById, eventById, setVoted } = props;
  const { data: productByEventAndCategory } = useQuery(
    QUERY_PRODUCT_BY_EVENT_AND_CATEGORY,
    {
      fetchPolicy: "no-cache",
      variables: {
        eventId: eventById?.eventById?.id || "no-event",
        categoryCode: color,
      },
    }
  );

  const [makerByCate, setMakerByCate] = useState([]);
  useEffect(() => {
    let makerByCategory = [];
    if (makerId) {
      const chooseMaker = makerById?.makerByEventId?.filter(
        (item) => item?.id === makerId
      );
      makerByCategory = chooseMaker?.filter(
        (item) => item?.categories[0].code === color
      );
    } else {
      makerByCategory = makerById?.makerByEventId?.filter(
        (item) => item?.categories[0].code === color
      );
    }
    if (makerByCategory) {
      makerByCategory.sort((a, b) => a.makerPosition - b.makerPosition);
    }
    setMakerByCate(makerByCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makerById, makerId]);
  let newMakerAndProduct = [];
  if (makerByCate && productByEventAndCategory) {
    const newList = makerByCate.map((item) => ({
      ...item,
      products: productByEventAndCategory.productByEventAndCategory.filter(
        (product) => product.maker.id === item.id
      ),
    }));
    newMakerAndProduct = newList.filter(
      (product) => product.products.length > 0
    );
  }
  return (
    <Wrapper flagBorder={newMakerAndProduct.length}>
      <CategoryName color={color}>
        {eventById && eventById.eventById.categoryNames[color]}
      </CategoryName>
      {newMakerAndProduct.length > 0 ? (
        newMakerAndProduct?.map((maker, idx) => {
          return (
            <Row
              key={idx}
              style={{
                transition: "0.3s",
                paddingLeft: "10px",
                paddingBottom: "10px",
                borderBottom: "1px solid #efefef",
                marginBottom: "10px",
              }}
            >
              <MakerName>{maker.name || ""}</MakerName>
              <Row
                justify="left"
                style={{
                  width: "100%",
                }}
                gutter={8}
                span={24}
              >
                {maker?.products
                  ?.sort((a, b) => (b.position > a.position ? -1 : 1))
                  .map((prod, idx) => {
                    return (
                      <Col key={idx} style={{ paddingTop: 10 }} xl={8} span={8}>
                        <ProductCard
                          title={prod?.name}
                          description={prod?.description}
                          urlImage={prod?.image}
                          data={prod}
                          maker={maker.name}
                          color={color}
                          onProcess={eventById.eventById.onProcess}
                          eventId={eventById?.eventById}
                          isIE={isIE}
                          setVoted={setVoted}
                        />
                      </Col>
                    );
                  })}
              </Row>
            </Row>
          );
        })
      ) : (
        <div>イベントに商品がありません。</div>
      )}
    </Wrapper>
  );
};

export default Category;
