/* eslint-disable */
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "../body/index.css";
import Category from "./Category/Category";
import {
  BLUE_COLOR,
  RED_COLOR,
  GREEN_COLOR,
} from "../../constants/constants.js";
import { useHistory } from "react-router-dom";
const BodyEvent = (props) => {
  const { loading, makerById, eventById, eventId } = props;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const history = useHistory();
  const [disableButton, setDisableButton] = useState(true);
  const [voted, setVoted] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const confirmVoted = () => {
    history.push({
      pathname: "/thanks",
      search: `?eventId=${eventId}`,
    });
  };

  useEffect(() => {
    if (
      !localStorage.getItem("confirmVoted") &&
      localStorage.getItem("voted")
    ) {
      setDisableButton(false);
    }
  }, [voted]);

  useEffect(() => {
    if (!localStorage.getItem("guestInfo")) {
      setShowButton(false);
    }
  }, []);

  return (
    <Spin
      style={{ marginTop: window.innerHeight / 2 }}
      indicator={antIcon}
      spinning={loading}
    >
      <div
        style={{
          padding: "70px 0px",
        }}
      >
        <div
          style={{
            height: "90vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {eventById && (
            <Row
              gutter={16}
              span={24}
              style={{ padding: "10px 70px 0 70px", height: "100%" }}
            >
              <Col xl={8} span={8}>
                <Category
                  key={RED_COLOR}
                  color={RED_COLOR}
                  makerById={makerById}
                  eventById={eventById}
                  setVoted={setVoted}
                />
              </Col>
              <Col xl={8} span={8}>
                <Category
                  key={GREEN_COLOR}
                  color={GREEN_COLOR}
                  makerById={makerById}
                  eventById={eventById}
                  setVoted={setVoted}
                />
              </Col>
              <Col xl={8} span={8}>
                <Category
                  key={BLUE_COLOR}
                  color={BLUE_COLOR}
                  makerById={makerById}
                  eventById={eventById}
                  setVoted={setVoted}
                />
              </Col>
            </Row>
          )}
        </div>
        {showButton &&
          eventById?.eventById?.id ===
            process.env.REACT_APP_CHUBU_CUSTOMER_VOTE_EVENT && (
            <button
              onClick={() => confirmVoted()}
              style={{
                color: "#ffffff",
                backgroundColor: "#FA7E37",
                borderRadius: "10px",
                width: "400px",
                height: "60px",
                border: "0",
                fontSize: "24px",
                cursor: disableButton ? "not-allowed" : "pointer",
                // opacity: disableButton && "0.5",
                margin: "5px 0",
                padding: "0 20px",
                position: "fixed",
                bottom: "0",
                transform: "translate(-50%, -20%)",
              }}
              disabled={disableButton}
            >
              {!disableButton
                ? "投票完了"
                : localStorage.getItem("confirmVoted")
                ? "引き替え済み"
                : "投票をよろしくお願いします。"}
            </button>
          )}
      </div>
    </Spin>
  );
};
export default BodyEvent;
