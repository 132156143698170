import React from 'react';
import './index.css';

const EventName = ({ children }) => {
  return <div className='eventName'>{children}</div>;
};
const StartTime = ({ children }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div className='textSize' style={{ textAlign: 'left' }}>
        開始時間
      </div>
      <div
        className='textSize'
        style={{ textAlign: 'left', fontWeight: '700' }}
      >
        {children}
      </div>
    </div>
  );
};
const EndTime = ({ children }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div className='textSize' style={{ textAlign: 'left' }}>
        終了時間
      </div>
      <div
        className='textSize'
        style={{ textAlign: 'left', fontWeight: '700' }}
      >
        {children}
      </div>
    </div>
  );
};
const Description = ({ children }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div className='textSize' style={{ textAlign: 'left' }}>
        説明
      </div>
      <div
        className='textSize'
        style={{
          textAlign: 'left',
          fontWeight: '700',
          maxWidth: '400px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

const MakerJoin = ({ children }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div className='textSize' style={{ textAlign: 'left' }}>
        イベントのメーカー数
      </div>
      <div
        className='textSize'
        style={{ textAlign: 'left', fontWeight: '700' }}
      >
        {children}
      </div>
    </div>
  );
};

export { EventName, StartTime, EndTime, Description, MakerJoin };
