import { LeftCircleOutlined, LikeTwoTone } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, message, Result, Spin } from "antd";
import * as queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import { VOTE_PRODUCT } from "../../graphql/MUTATION/voteProduct";
import { QUERY_PRODUCT_IN_EVENT_ACTIVE } from "../../graphql/QUERY/event";

const Page404 = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  );
};
const VotingScreen = () => {
  const { productId, eventId, qrCode } = queryString.parse(
    window.location.search
  );
  var isTrue = qrCode === "true";
  const history = useHistory();
  const { data: productData, error } = useQuery(QUERY_PRODUCT_IN_EVENT_ACTIVE, {
    variables: { productId, eventId },
    fetchPolicy: "network-only",
  });

  // eslint-disable-next-line no-undef
  const [createVoteProduct] = useMutation(VOTE_PRODUCT);
  const [spin, setSpin] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  if (!productId || !eventId) return <Page404 />;

  const handleVoting = () => {
    setIsLoading(true);
    try {
      createVoteProduct({
        variables: {
          eventId,
          productId,
          qrVote: isTrue,
        },
      })
        .then((_) => {
          message.success("投票が成功しました。");
          localStorage.setItem(`${eventId.concat(productId)}`, true);
          localStorage.setItem(productId, true);
          setIsLoading(false);
        })
        .catch((error) => {
          message.error(error?.graphQLErrors?.[0]?.message);
          setIsLoading(false);
        });
    } catch (error) {
      console.log("err" + error);
      setIsLoading(true);
    }
  };

  if (error || !productData?.productInEventActive) {
    setTimeout(() => {
      setSpin(false);
    }, 7000);
    return (
      <div style={{ marginTop: "40vh" }}>
        {spin ? (
          <Spin />
        ) : (
          <div>
            <Result title={"イベントを実施しておりません。"} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        height: window.innerHeight,
        overflowY: "auto",
        marginBottom: 30,
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "center", padding: "10px" }}
      >
        <div
          className="styleCard"
          style={{
            boxShadow: "0 0 11px rgba(33, 33, 33, 0.2)",
            border: "1px solid #4f81bd",
            transition: "0.3s",
            background: "#fff",
            float: "left",
            height: "100%",
            width: "100%",
            cursor: "pointer",
            padding: "5px",
          }}
        >
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "65vh",
            }}
          >
            <img
              style={{
                paddingTop: 5,
                maxWidth: "100%",
                maxHeight: "100%",
                display: "inline-block",
              }}
              alt="example"
              src={productData?.productInEventActive.image}
            />
          </div>
          <div
            style={{
              fontSize: "17px",
              fontWeight: "700",
              fontFamily: "Lato, sans-serif",
              color: "#0d2830",
              paddingRight: "10px",
              textAlign: "left",
              marginLeft: "10px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              overflow: "hidden",
            }}
          >
            {productData?.productInEventActive.name}
          </div>
          <div
            style={{
              fontSize: "14px",
              textAlign: "left",
              fontFamily: "Lato,sans-serif",
              color: "#77798c",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              overflow: "hidden",
              marginLeft: "10px",
              paddingRight: "10px",
            }}
          >
            {productData?.productInEventActive.description
              ? productData?.productInEventActive.description
              : " *no description !"}
          </div>
          <div
            style={{
              textTransform: "uppercase",
              color: "#376092",
              fontWeight: "600",
              fontSize: "16px",
              fontFamily: "inherit",
              width: "100%",
              marginLeft: "10px",
              paddingRight: "10px",
              display: "flex",
            }}
          >
            メーカー: {productData?.productInEventActive.maker.name}
          </div>
          <div>
            <Button
              onClick={handleVoting}
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "17px",
                alignItems: "center",
                marginTop: "10px",
                width: "100%",
                background: localStorage.getItem(eventId.concat(productId))
                  ? "#BFBFBF"
                  : "yellow",
                fontWeight: "bold",
                color: localStorage.getItem(eventId.concat(productId))
                  ? "#000000"
                  : "red",
              }}
              disabled={Boolean(
                localStorage.getItem(eventId.concat(productId))
              )}
              loading={isLoading}
            >
              <div>
                {localStorage.getItem(eventId.concat(productId)) ? (
                  ""
                ) : (
                  <LikeTwoTone twoToneColor="#eb2f96" />
                )}
                {localStorage.getItem(eventId.concat(productId))
                  ? "投票済"
                  : "いいね!投票"}
              </div>
            </Button>
            {localStorage.getItem(eventId.concat(productId)) && (
              <Button
                type="primary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "17px",
                  alignItems: "center",
                  marginTop: "10px",
                  width: "100%",
                }}
                onClick={() => history.push(`/event?eventId=${eventId}`)}
              >
                <div style={{ marginRight: "10px" }}>
                  <LeftCircleOutlined />
                </div>
                いいね！投票ページへ
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VotingScreen;
