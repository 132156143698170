import React from "react";
// import './productCard.css';
import { Tooltip } from "antd";
import styled from "styled-components";
import bg_pc from "../../../image/gift-bg.png";

export const Container = styled.div`
  width: 100vw;
  height: ${window.innerHeight}px;
  background-size: auto ${window.innerHeight}px;
  background-image: url(${bg_pc});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image {
    display: block;
    width: 25vw;
    @media (max-width: 1000px) {
      width: ${window.innerHeight > window.innerWidth ? "75vw" : "45vh"};
    }
    @media (max-width: 700px) {
      width: ${window.innerHeight > window.innerWidth ? "85vw" : "30vh"};
    }
  }
  .bottom {
    width: 20vw;
    margin-top: 20px;
    @media (max-width: 1000px) {
      width: ${window.innerHeight > window.innerWidth ? "75vw" : "30vh"};
    }
  }
  .center {
    width: auto;
    height: ${window.innerHeight / 2}px;
  }
`;
