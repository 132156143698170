import React from "react";
import "./productCard.css";
import { Tooltip } from "antd";
import LazyLoad from "react-lazy-load";

const ProductCardWrapper = (props) => {
  return (
    <Tooltip placement="rightTop" title="クリックして詳細を見る">
      <div
        className={
          props.color === "RED"
            ? "styleCardRed"
            : props.color === "GREEN"
            ? "styleCardGreen"
            : "styleCard"
        }
        onClick={props.onClick}
        style={{
          // boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          border: "1px solid #efefef",

          transition: "0.3s",
          background: "#fff",
          float: "left",
          height: "100%",
          width: "100%",
          cursor: "pointer",
        }}
      >
        {props.children}
      </div>
    </Tooltip>
  );
};

const ProductImageWrapper = (props) => {
  return (
    <div
      style={{
        height: "150px",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.children}
    </div>
  );
};

const ProductImage = (props) => {
  return (
    <LazyLoad offsetVertical={100}>
      <img
        style={{
          paddingTop: 5,
          maxWidth: "100%",
          maxHeight: "100%",
          display: "inline-block",
        }}
        alt="example"
        src={props.urlImage}
      />
    </LazyLoad>
  );
};

const ProductImageIE = (props) => {
  return (
    <img
      style={{
        paddingTop: 5,
        width: "175px",
        maxHeight: "100%",
        display: "inline-block",
      }}
      alt="example"
      src={props.urlImage}
    />
  );
};

const ProductTitle = (props) => {
  return (
    <div
      style={{
        fontSize: "17px",
        fontWeight: "700",
        fontFamily: "Lato, sans-serif",
        color: "#0d2830",
        paddingRight: "10px",
        textAlign: "left",
        marginLeft: "10px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {props.children}
    </div>
  );
};

const ProductDescription = (props) => {
  return (
    <div
      style={{
        fontSize: "14px",
        textAlign: "left",
        fontFamily: "Lato,sans-serif",
        color: "#77798c",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        overflow: "hidden",
        marginLeft: "10px",
        paddingRight: "10px",
      }}
    >
      {props.children}
    </div>
  );
};

const WrapButton = (props) => {
  return (
    <div style={{ margin: "5px", display: "flex", justifyContent: "center" }}>
      {props.children}
    </div>
  );
};

const WrapModal = (props) => {
  return <div style={{ display: "flex", marginTop: 10 }}>{props.children}</div>;
};
const WrapContent = (props) => {
  return <div style={{ flex: 1 }}>{props.children}</div>;
};
const WrapImage = (props) => {
  return (
    <div style={{ flex: 1 }}>
      <img
        style={{
          paddingTop: 5,
          maxWidth: "300px",
          maxHeight: "300px",
          // maxWidth: '100%',
          // maxHeight: '100%',
          display: "inline-block",
        }}
        alt="example"
        src={props.urlImage}
      />
    </div>
  );
};

const TitleModal = (props) => {
  return (
    <div
      style={{
        fontSize: "25px",
        fontWeight: "700",
        fontFamily: "Lato, sans-serif",
        color: "#0d2830",
        textAlign: "left",
        marginLeft: "10px",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {props.children}
    </div>
  );
};

const DescriptionModal = (props) => {
  return (
    <div
      style={{
        fontSize: "16px",
        textAlign: "left",
        fontFamily: "Lato,sans-serif",
        color: "#77798c",
        width: "100%",
        overflow: "hidden",
        marginLeft: "10px",
        paddingRight: "10px",
      }}
    >
      {props.children}
    </div>
  );
};

const MakerModal = (props) => {
  return (
    <div
      style={{
        textTransform: "uppercase",
        color: "#376092",
        fontWeight: "600",
        fontSize: "16px",
        fontFamily: "inherit",
        width: "100%",
        marginLeft: "10px",
        paddingRight: "10px",
        marginTop: "5px",
      }}
    >
      {props.children}
    </div>
  );
};

const CategoryModal = (props) => {
  return (
    <div
      style={{
        fontSize: "14px",
        textAlign: "left",
        fontFamily: "Lato,sans-serif",
        color: "#77798c",
        width: "100%",
        overflow: "hidden",
        marginLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "10px",
        marginTop: "5px",
        borderBottom: "1px solid #efefef",
      }}
    >
      {props.children}
    </div>
  );
};

export {
  MakerModal,
  CategoryModal,
  DescriptionModal,
  TitleModal,
  WrapModal,
  WrapImage,
  WrapContent,
  ProductDescription,
  ProductImageWrapper,
  ProductImage,
  ProductTitle,
  ProductCardWrapper,
  WrapButton,
  ProductImageIE,
};
