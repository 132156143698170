import React, { useState, useEffect } from "react";
import BodyEvent from "../../components/body/bodyEvent";
import Header from "../../components/header/header";
import HeaderMobile from "../../components/header/headerMobile";
import BodyMobile from "../../components/body/bodyMobile";
import "./index.css";
import * as queryString from "query-string";
import { useQuery } from "@apollo/react-hooks";
import { QUERY_EVENTS_BY_ID } from "../../graphql/QUERY/event";
import { MAKER_BY_EVENT_ID } from "../../graphql/QUERY/maker";

import { Result, Spin } from "antd";
import { useHistory, useLocation } from "react-router-dom";

let _eventId = "";

const MainContent = () => {
  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  const DeviceType = getDeviceType();
  const history = useHistory();

  const parameters = queryString.parse(window.location.search);
  const { viewonly, eventId, id, companyName, name, tel } = parameters;
  _eventId = eventId || viewonly || "";
  const guestInfo = {
    id,
    companyName,
    name,
    tel,
  };

  const getGuestInfo = JSON.parse(localStorage.getItem("guestInfo"));

  useEffect(() => {
    if (id && eventId === process.env.REACT_APP_CHUBU_CUSTOMER_VOTE_EVENT) {
      if (getGuestInfo && getGuestInfo.id !== id) {
        localStorage.removeItem("confirmVoted");
        localStorage.removeItem("voted");
        localStorage.removeItem("comment");
      }
      localStorage.setItem("guestInfo", JSON.stringify(guestInfo));
      history.push({
        pathname: "/event",
        search: `?eventId=${eventId}`,
      });
    }
    localStorage.setItem("eventId", eventId);
  }, []);

  const {
    data: eventById,
    error: eventError,
    loading,
  } = useQuery(QUERY_EVENTS_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: _eventId,
    },
  });
  const { data: makerById, error: makerError } = useQuery(MAKER_BY_EVENT_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: _eventId,
    },
  });
  if (eventError || makerError || !eventById?.eventById) {
    if (DeviceType === "desktop") {
      if (loading)
        return (
          <Spin
            style={{ marginTop: "35vh" }}
            spinning={true}
            size={"large"}
          ></Spin>
        );
      return (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      );
    } else {
      if (loading) return "";
      return (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      );
    }
  } else {
    return (
      <div className="layout_cover">
        {DeviceType === "desktop" ? (
          <>
            <Header
              eventById={eventById}
              makerById={makerById}
              loading={loading}
            />
            <BodyEvent
              eventById={eventById}
              makerById={makerById}
              loading={loading}
              eventId={eventId}
            />
          </>
        ) : (
          <>
            <HeaderMobile
              eventById={eventById}
              makerById={makerById}
              loading={loading}
              deviceType={DeviceType}
            />
            <BodyMobile
              eventById={eventById}
              makerById={makerById}
              loading={loading}
              deviceType={DeviceType}
              eventId={eventId}
            />
            <div style={{ height: 40 }}></div>
          </>
        )}
      </div>
    );
  }
};
export default MainContent;
