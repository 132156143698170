/* eslint-disable */
import { Col, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import MakerConText from "../../context/makerContext";

import {
  CustomSelect,
  HeaderTitle,
  TitleSelect,
  WrapperHeader,
  WrapperSelect,
} from "../header/header.style";
import "./header.css";

const { Option } = Select;

const Header = (props) => {
  const { makerById, loading, eventById } = props;
  const [onProcess, setOnProcess] = useState(eventById.eventById?.onProcess);
  const [defaultValue, SetDefaultValue] = useState("");
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    setOnProcess(eventById.eventById?.onProcess);
  }, [eventById.eventById?.onProcess]);

  let guestInfo = JSON.parse(localStorage.getItem("guestInfo"));
  useEffect(() => {
    if (guestInfo) {
      setCompanyName(guestInfo?.companyName || guestInfo?.name);
    }
  });

  const listMaker = [];
  if (makerById?.makerByEventId.length) {
    listMaker.push(
      <Option key={"default"} value="">
        すべてのメーカー
      </Option>
    );
    for (let i = 0; i < makerById?.makerByEventId.length; i++) {
      listMaker.push(
        <Option key={i} value={makerById?.makerByEventId[i].id}>
          {makerById?.makerByEventId[i].name}
        </Option>
      );
    }
  }
  console.log("111111", eventById);
  return (
    <WrapperHeader>
      <div style={{ display: "flex", marginTop: 5, marginBottom: 5 }}>
        <div style={{ flex: 1 }}>
          <div
            style={{
              fontSize: "1.2rem",
              fontWeight: 700,
              color: "#FFFFFF",
            }}
          >
            いいね！ 投票
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                fontSize: "1rem",
                fontWeight: 700,
                color: "#FFFFFF",
              }}
            >
              SINCE
            </div>
            <div
              style={{
                fontSize: "1rem",
                fontWeight: 700,
                color: "#ff0000",
                marginLeft: "3px",
              }}
            >
              2020.11
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <HeaderTitle>投票商品検索</HeaderTitle>
        </div>
        <div style={{ flex: 1, paddingTop: 10, paddingRight: 10 }}>
          <WrapperSelect>
            <TitleSelect>メーカーの選択</TitleSelect>
            <CustomSelect>
              <MakerConText.Consumer>
                {({ addMaker }) => (
                  <Select
                    style={{ width: 200, zIndex: 100 }}
                    defaultValue={defaultValue}
                    onChange={addMaker}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {listMaker}
                  </Select>
                )}
              </MakerConText.Consumer>
            </CustomSelect>
          </WrapperSelect>
        </div>
        {eventById?.eventById?.id ===
          process.env.REACT_APP_CHUBU_CUSTOMER_VOTE_EVENT && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0 20px",
              color: "white",
              fontSize: "16px",
            }}
          >
            <span>{companyName}</span>
            {!guestInfo?.companyName &&
              eventById === process.env.REACT_APP_CHUBU_CUSTOMER_VOTE_EVENT && (
                <span style={{ fontSize: "11px", marginTop: "4px" }}>様</span>
              )}
          </div>
        )}
      </div>
    </WrapperHeader>
  );
};

export default Header;
