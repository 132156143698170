import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, split } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { getMainDefinition } from 'apollo-utilities'

const graphqlUrl = process.env.REACT_APP_API_URL || `${process.env.REACT_APP_SSL === 'true' ? 'https' : 'http'}://${window.location.host}/graphql`
const socketEndpoint = process.env.REACT_APP_SOCKET_URL || `${process.env.REACT_APP_SSL === 'true' ? 'wss' : 'ws'}://${window.location.host}/graphql`

const httpLink = new HttpLink({
    uri: graphqlUrl
})
const wsClient = new SubscriptionClient(socketEndpoint, {
    reconnect: true,
    connectionParams: {
        'authorization': `Bearer ${window.localStorage.getItem('access_token')}`
    }
})
const wsLink = new WebSocketLink(wsClient)
const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'authorization': `Bearer ${window.localStorage.getItem('access_token')}`
        }
    }))
    return forward(operation)
})
const link = split(
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query)
        return kind === 'OperationDefinition' && operation === 'subscription'
    }, wsLink, httpLink
)
const Client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache({
        addTypename: false
    })
})

export default Client
