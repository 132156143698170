/* eslint-disable */
import React from "react";
import { Bar } from "react-chartjs-2";
import { Result } from "antd";
import {
  Wrapper,
  WrapperNameCategory,
  NameCategory,
  Label,
  NoResult,
} from "./Custom";
import categories_colors from "../../constants/categories-color";

const TotalChart = ({ data }) => {
  const dataSort = data?.products
    .sort(function (a, b) {
      return b.vote + b.qrVote - (a.vote + a.qrVote);
    })
    .slice(0, 30);

  const dataAll = {
    labels: dataSort?.map((item) => {
      return item.graphDisplayName || item.name;
    }),
    datasets: [
      {
        label: "リアル投票",
        borderWidth: 1,
        backgroundColor: categories_colors[4].hex_primary,
        hoverBackgroundColor: categories_colors[4].hex_secondary,
        data: dataSort?.map((item) => {
          return item.qrVote;
        }),
      },
      {
        label: "WEB投票",
        backgroundColor: categories_colors[3].hex_secondary,
        hoverBackgroundColor: categories_colors[3].hex_primary,
        borderWidth: 1,
        data: dataSort?.map((item) => {
          return item.vote;
        }),
      },
    ],
  };

  return (
    <Wrapper>
      <WrapperNameCategory>
        <NameCategory>いいね！投票：総合ランキング</NameCategory>
        <Label color={categories_colors[3].hex_secondary} />
      </WrapperNameCategory>
      {data?.products.length === 0 ? (
        <NoResult>
          <Result title="データがありません" />
        </NoResult>
      ) : (
        <Bar data={dataAll} options={options} />
        // <div
        //   style={{
        //     overflowX: dataSort?.length > 30 ? "auto" : "hidden",
        //   }}
        // >
        //   <div
        //     style={{
        //       width: dataSort?.length > 30 ? dataSort?.length * 30 : "100%",
        //     }}
        //   >
        //     <Bar height={65} data={dataAll} options={options} />
        //   </div>
        // </div>
      )}
    </Wrapper>
  );
};

const options = {
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          min: 0,
          beginAtZero: 0,
          callback: (value) => {
            if (typeof value !== "string") {
              return "グラフ表示名".replaceAll(/[ ,ー,一,－]/g, "|").split("");
            } else {
              if (value.length > 5) {
                return value
                  .slice(0, 20)
                  .replaceAll(/[ ,ー,一,－]/g, "|")
                  .split("");
              } else {
                return value.replaceAll(/[ ,ー,一,－]/g, "|").split("");
              }
            }
          },
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return value === Math.round(value) ? value : null;
          },
        },
        scaleLabel: {
          display: true,
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      display: false,
      color: "white",
    },
  },
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem) {
        return tooltipItem.yLabel;
      },
    },
  },
};

export default TotalChart;
