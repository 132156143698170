import gql from 'graphql-tag';

const QUERY_PRODUCT_BY_EVENT_AND_MAKER = gql`
  query productByEventAndMaker($eventId: String!, $makerId: String!) {
    productByEventAndMaker(eventId: $eventId, makerId: $makerId) {
      id
      name
      graphDisplayName
      description
      image
      position
    }
  }
`;

const QUERY_PRODUCT_BY_EVENT_AND_CATEGORY = gql`
  query productByEventAndCategory($categoryCode: String!, $eventId: String!) {
    productByEventAndCategory: productByEventAndCategoryNoAuth(
      categoryCode: $categoryCode
      eventId: $eventId
    ) {
      id
      name
      graphDisplayName
      description
      image
      maker {
        id
        name
      }
      vote
      qrVote
      position
    }
  }
`;

const QUERY_PRODUCTS_BY_EVENT_ID = gql`
  query productByEventId($eventId: String!) {
    products: productByEventIdNoAuth(eventId: $eventId) {
      id
      name
      graphDisplayName
      position
      vote
      qrVote
      category {
        code
      }
      maker {
        id
        name
      }
    }
  }
`;

const QUERY_PRODUCT = gql`
  query products(
    $makerId: String
    $categoryCode: String
    $searchText: String
    $offset: Float
    $limit: Float
  ) {
    products(
      makerId: $makerId
      categoryCode: $categoryCode
      searchText: $searchText
      offset: $offset
      limit: $limit
    ) {
      count
      products {
        id
        name
        graphDisplayName
        description
        image
        category {
          code
          name
          description
        }
        maker {
          id
          name
          description
          categories {
            code
            name
            description
          }
          createdAt
          numberOfProducts
        }
        createdAt
      }
    }
  }
`;
const QUERY_PRODUCT_BY_ID = gql`
  query productById($productId: String!) {
    productById(id: $productId) {
      id
      name
      graphDisplayName
      description
      image
      category {
        code
        name
        description
      }
      maker {
        id
        name
        description
      }
      vote
      position
      createdAt
    }
  }
`;

export {
  QUERY_PRODUCT,
  QUERY_PRODUCT_BY_ID,
  QUERY_PRODUCT_BY_EVENT_AND_CATEGORY,
  QUERY_PRODUCT_BY_EVENT_AND_MAKER,
  QUERY_PRODUCTS_BY_EVENT_ID,
};
