const categories_colors = [
  {
    color: 'GREEN',
    hex_primary: '#b7eb8f',
    hex_secondary: '#B7DFA4',
  },
  {
    color: 'RED',
    hex_primary: '#E06769',
    hex_secondary: '#E7888A',
  },
  {
    color: 'BLUE',
    hex_primary: '#91d5ff',
    hex_secondary: '#85CCFF',
  },
  {
    color: 'YELLOW',
    hex_primary: '#FFC20A',
    hex_secondary: '#FFD65C',
  },
  {
    color: 'BLUE_2',
    hex_primary: '#5979C6',
    hex_secondary: '#70A9FF',
  },
];
export default categories_colors;
