import gql from 'graphql-tag';

const MAKER_BY_EVENT_ID = gql`
  query makerByEventId($id: String!) {
    makerByEventId:makerByEventIdNoAuth(id: $id) {
      id
      name
      categories {
        code
      }
      makerPosition
    }
  }
`;

const QUERY_MAKERS = gql`
  query makers {
    makers {
      id
      name
      numberOfProducts
      categories {
        name
        code
        description
      }
      description
      createdAt
    }
  }
`;

export { QUERY_MAKERS, MAKER_BY_EVENT_ID };
