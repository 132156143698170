import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Modal, Radio, Spin, Tag, Button } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { isMobileSafari } from "react-device-detect";
import {
  BLUE_COLOR,
  GREEN_COLOR,
  RED_COLOR,
} from "../../constants/constants.js";
import "../body/bodyMobile.css";
import { useDispatch } from "react-redux";
import Category from "../body/Category/CategoryMobile";
import { useHistory } from "react-router-dom";
import MakerContext from "../../context/makerContext.js";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const subS = (text) => {
  if (text?.length > 15) {
    return text?.substring(0, 15)?.concat("...");
  }
  return text;
};

const BodyMobile = (props) => {
  const { loading, makerById, eventById, deviceType, eventId } = props;
  const { addMaker } = useContext(MakerContext);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [firstModal, setFirstModal] = useState(true);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const history = useHistory();
  const [disableButton, setDisableButton] = useState(true);
  const [voted, setVoted] = useState(false);
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    if (
      !localStorage.getItem("confirmVoted") &&
      localStorage.getItem("voted")
    ) {
      setDisableButton(false);
    }
  }, [voted]);

  useEffect(() => {
    if (!localStorage.getItem("guestInfo")) {
      setShowButton(false);
    }
  }, []);

  useEffect(() => {
    ref.current = (
      <div key={`cate-div-${GREEN_COLOR}`}>
        <Divider orientation="center" style={{ margin: 0 }}>
          <Tag
            style={{ fontSize: 15, margin: 0 }}
            color={"red"}
            key={"1"}
            onClick={() => {
              setFirstModal(true);
            }}
          >
            {eventById && eventById.eventById.categoryNames[RED_COLOR]}
          </Tag>
        </Divider>
        <Category
          key={`cate-${RED_COLOR}`}
          color={RED_COLOR}
          makerById={makerById}
          eventById={eventById}
          deviceType={deviceType}
          setVoted={setVoted}
        />
      </div>
    );
  }, [deviceType, eventById, makerById]);
  const handleSelectCategory = (value) => {
    const _value = value;
    dispatch({
      type: "SET_CURRENT_CATEGORY",
      payload: _value,
    });
    if (_value === "RED") {
      ref.current = (
        <div key={`cate-div-${GREEN_COLOR}`}>
          <Divider orientation="center" style={{ margin: 0 }}>
            <Tag
              style={{ fontSize: 15, margin: 0 }}
              color={"red"}
              key={"1"}
              onClick={() => {
                setFirstModal(true);
              }}
            >
              {eventById && eventById.eventById.categoryNames[RED_COLOR]}
            </Tag>
          </Divider>
          <Category
            key={`cate-${RED_COLOR}`}
            color={RED_COLOR}
            makerById={makerById}
            eventById={eventById}
            deviceType={deviceType}
            setVoted={setVoted}
          />
        </div>
      );
    }
    if (_value === "GREEN") {
      ref.current = (
        <div key={`cate-div-${GREEN_COLOR}`}>
          <Divider orientation="center" style={{ margin: 0 }}>
            <Tag
              style={{ fontSize: 15, margin: 0 }}
              color={"green"}
              key={"1"}
              onClick={() => {
                setFirstModal(true);
              }}
            >
              {eventById && eventById.eventById.categoryNames[GREEN_COLOR]}
            </Tag>
          </Divider>

          <Category
            key={`cate-${GREEN_COLOR}`}
            color={GREEN_COLOR}
            makerById={makerById}
            eventById={eventById}
            deviceType={deviceType}
            setVoted={setVoted}
          />
        </div>
      );
    }
    if (_value === "BLUE") {
      ref.current = (
        <div key={`cate-div-${BLUE_COLOR}`}>
          <Divider orientation="center" style={{ margin: 0 }}>
            <Tag
              style={{ fontSize: 15, margin: 0 }}
              color={"blue"}
              key={"1"}
              onClick={() => {
                setFirstModal(true);
              }}
            >
              {eventById && eventById.eventById.categoryNames[BLUE_COLOR]}
            </Tag>
          </Divider>
          <Category
            key={`cate-${BLUE_COLOR}`}
            color={BLUE_COLOR}
            makerById={makerById}
            eventById={eventById}
            deviceType={deviceType}
            setVoted={setVoted}
          />
        </div>
      );
    }
    setFirstModal(false);
    addMaker("");
    return ref.current;
  };

  const confirmVoted = () => {
    history.push({
      pathname: "/thanks",
      search: `?eventId=${eventId}`,
    });
  };

  return (
    <Spin
      style={{ marginTop: window.innerHeight / 2 }}
      indicator={antIcon}
      spinning={loading}
    >
      <Modal
        title="投票するカテゴリを選択して下さい。​"
        visible={firstModal}
        okText="确认"
        cancelText="取消"
        width={"90vw"}
        onCancel={() => {
          setFirstModal(false);
        }}
        footer={false}
      >
        <div>
          <RadioGroup
            onChange={({ target: { value } }) => {
              handleSelectCategory(value);
            }}
            defaultValue="a"
            size="large"
          >
            <RadioButton value="RED" style={{ color: "red", marginBottom: 5 }}>
              {subS(eventById?.eventById?.categoryNames[RED_COLOR])}
            </RadioButton>
            <br />
            <RadioButton
              value="GREEN"
              style={{
                color: "green",
                marginBottom: 5,
                wordWrap: "inherit",
              }}
            >
              {subS(eventById?.eventById?.categoryNames[GREEN_COLOR])}
            </RadioButton>
            <br />
            <RadioButton value="BLUE" style={{ color: "blue" }}>
              {subS(eventById?.eventById?.categoryNames[BLUE_COLOR])}
            </RadioButton>
          </RadioGroup>
        </div>
      </Modal>

      <div
        style={{
          // height: isMobileSafari ? "89vh" : "86vh",
          marginBottom: 30,
          // overflowY: "overlay",
          // overflowX: "hidden",
          paddingBottom: 160,
        }}
      >
        <div
          style={{
            marginTop: "5px",
            color: "red",
            padding: "0 10px",
            display: "grid",
          }}
        >
          <span>クリックするとカテゴリの切り替えが出来ます。</span>
          <span>👇</span>
        </div>
        {eventById && <div style={{ marginTop: 0 }}>{ref.current}</div>}
      </div>
      {showButton &&
        eventById?.eventById?.id ===
          process.env.REACT_APP_CHUBU_CUSTOMER_VOTE_EVENT && (
          <button
            onClick={confirmVoted}
            style={{
              color: "#ffffff",
              backgroundColor: "#FA7E37",
              borderRadius: "10px",
              width: "300px",
              height: "50px",
              border: "0",
              fontSize: "16px",
              cursor: disableButton ? "not-allowed" : "pointer",
              // opacity: disableButton && "0.5",
              padding: "0 20px",
              position: "fixed",
              bottom: "0",
              transform: "translate(-50%, -20%)",
            }}
            disabled={disableButton}
          >
            {!disableButton
              ? "投票完了"
              : localStorage.getItem("confirmVoted")
              ? "引き替え済み"
              : "投票をよろしくお願いします。"}
          </button>
        )}
    </Spin>
  );
};

export default BodyMobile;
