import React from 'react';

const WrapperHeader = (props) => {
  return (
    <div
      style={{
        transition: '0.3s',
        backgroundColor: 'rgb(57, 62, 65)',
        position: 'fixed',
        width: '100%',
        zIndex: '10',
        marginRight: 20,
        // margin: '5px',
        // border: '1px solid #ff282f',
      }}
    >
      {props.children}
    </div>
  );
};
const HeaderTitle = (props) => {
  return (
    <div
      style={{
        fontSize: '2rem',
        fontWeight: 700,
        // color: '#376092',
        color: '#FFFFFF',
      }}
    >
      {props.children}
    </div>
  );
};
const WrapperSelect = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        // backgroundColor: '#00cc99',
        // padding: '10px 5px',
        // border: '1px solid #efefef',
      }}
    >
      {props.children}
    </div>
  );
};
const TitleSelect = (props) => {
  return (
    <div
      style={{
        // flex: 1,
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#096dd9',
        marginRight: '10px'
      }}
    >
      {props.children}
    </div>
  );
};

const CustomSelect = (props) => {
  return (
    <div
      style={{
        // flex: 1,
      }}
    >
      {props.children}
    </div>
  );
};

export { WrapperHeader, HeaderTitle, WrapperSelect, TitleSelect, CustomSelect };
