let initialState = {
    onProcessing: false,
    currentCategory: "RED",
};

const reducer = (state = initialState, action) => {
    if (action.type === 'SET_PROCESSING') {
        state = { ...state, onProcessing: action.payload };
        return state;
    }
    if (action.type === 'SET_CURRENT_CATEGORY') {
        state = { ...state, currentCategory: action.payload };
        return state;
    }
    return state;
};

export default reducer;
