import React from 'react';

const MakerNameMB = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        marginLeft: '10px',
        textTransform: 'uppercase',
        color: '#376092',
        // color: '#ffffff',
        fontWeight: '700',
        fontSize: props.deviceType === 'tablet' ? '17px' : '14px',
        fontFamily: 'inherit',
        // borderBottom: '1px solid #efefef',
        marginTop: '10px',
        width: '100%',
      }}
    >
      {props.children}
    </div>
  );
};

export { MakerNameMB };
