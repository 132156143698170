import React, { useState } from "react";
import { Button, message, Modal, Tag } from "antd";
import {
  WrapperContent,
  ProductName,
  Description,
  WrapButton,
  ImageModal,
  ProductNameModal,
  DescriptionModal,
  MakerModal,
  CategoryModal,
  WrapperImage,
} from "../Product/ProductCardMobile.style";
import { useMutation } from "@apollo/react-hooks";
import { VOTE_PRODUCT } from "../../../graphql/MUTATION/voteProduct";
import * as queryString from "query-string";
import { LikeTwoTone } from "@ant-design/icons";

const ProductCard = ({
  deviceType,
  title,
  urlImage,
  description,
  data,
  onProcess,
  eventId,
  color,
  maker,
  setVoted,
  ...props
}) => {
  const [createVoteProduct] = useMutation(VOTE_PRODUCT);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const parameters = queryString.parse(window.location.search);
  const { viewonly } = parameters;
  const showModalProduct = () => {
    setVisible(true);
  };
  const handleVoteProduct = () => {
    let guestInfo = JSON.parse(localStorage.getItem("guestInfo"));
    setIsLoading(true);
    let input = {
      eventId: eventId.id,
      productId: data.id,
      customerInput: {
        id: guestInfo?.id ? guestInfo?.id : "",
        companyName: guestInfo?.companyName ? guestInfo?.companyName : "",
        name: guestInfo?.name ? guestInfo?.name : "",
        tel: guestInfo?.tel ? guestInfo?.tel : "",
      },
    };
    if (eventId.id !== process.env.REACT_APP_CHUBU_CUSTOMER_VOTE_EVENT) {
      delete input.customerInput;
    }
    try {
      createVoteProduct({
        variables: input,
      })
        .then((_) => {
          message.success("投票が成功しました。");
          localStorage.setItem(`${data.id}`, true);
          localStorage.setItem("voted", true);
          setVoted(true);
          setIsLoading(false);
          setVisible(false);
        })
        .catch((error) => {
          message.error(error?.graphQLErrors?.[0]?.message);
          setIsLoading(false);
        });
    } catch (error) {
      console.log("err" + error);
      setIsLoading(true);
    }
  };

  const hideModal = () => {
    setVisible(false);
  };
  return (
    <>
      {visible && (
        <Modal
          title=""
          visible={true}
          onOk={hideModal}
          onCancel={hideModal}
          okText="确认"
          cancelText="取消"
          footer={false}
          width={"90vw"}
          centered
        >
          <ImageModal deviceType={deviceType} urlImage={urlImage} />
          <ProductNameModal deviceType={deviceType}>{title}</ProductNameModal>
          <DescriptionModal deviceType={deviceType}>
            {description
              ? description.length > 100
                ? description.substring(0, 100)
                : description
              : " *no description !"}
          </DescriptionModal>
          <MakerModal deviceType={deviceType}>メーカー: {maker}</MakerModal>
          <CategoryModal deviceType={deviceType}>
            <Tag
              color={
                color === "RED" ? "red" : color === "GREEN" ? "green" : "blue"
              }
            >
              {eventId.categoryNames[color]}
            </Tag>
          </CategoryModal>
          {onProcess ? (
            <WrapButton>
              <Button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: deviceType === "tablet" ? "22px" : "15px",
                  alignItems: "center",
                  height: deviceType === "tablet" ? "40px" : "",
                  marginTop: "10px",
                  cursor:
                    viewonly || !!localStorage.getItem(data.id)
                      ? "default"
                      : "pointer",
                  width: "100%",
                  background:
                    viewonly || !!localStorage.getItem(data.id)
                      ? "#BFBFBF"
                      : "yellow",
                  fontWeight: "bold",
                  color:
                    viewonly || !!localStorage.getItem(data.id)
                      ? "#000000"
                      : "red",
                }}
                disabled={viewonly || !!localStorage.getItem(data.id)}
                onClick={() => {
                  handleVoteProduct();
                }}
                loading={isLoading}
              >
                <div>
                  {viewonly || !!localStorage.getItem(data.id) ? (
                    ""
                  ) : (
                    <LikeTwoTone twoToneColor="#eb2f96" />
                  )}

                  {viewonly || !!localStorage.getItem(data.id)
                    ? "投票済"
                    : "いいね!投票"}
                </div>
              </Button>
            </WrapButton>
          ) : (
            <div style={{ marginLeft: 10, color: "red" }}>
              イベントを実施しておりません。
            </div>
          )}
        </Modal>
      )}
      <WrapperContent
        deviceType={deviceType}
        onClick={() => {
          showModalProduct();
        }}
      >
        <WrapperImage
          deviceType={deviceType}
          urlImage={urlImage}
        ></WrapperImage>
        <ProductName deviceType={deviceType}>{title}</ProductName>
        <Description deviceType={deviceType} onProcess={onProcess}>
          {description ? description : "*No Description !"}
        </Description>
        <Button
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: deviceType === "tablet" ? "22px" : "12px",
            alignItems: "center",
            height: deviceType === "tablet" ? "40px" : "",
            marginTop: "10px",
            width: "100%",
            background: "yellow",
            fontWeight: "bold",
            color: "red",
          }}
        >
          詳細を見る
        </Button>
      </WrapperContent>
    </>
  );
};

export default ProductCard;
