import React from 'react';
import { Pie } from 'react-chartjs-2';
import categories_colors from '../../constants/categories-color';
import 'chartjs-plugin-datalabels';

const TotoPieChart = ({
  RedTotal,
  GreenTotal,
  BlueTotal,
  deviceType,
  titleNameBlue,
  titleNameGreen,
  titleNameRed,
  ...props
}) => {
  const Red = RedTotal?.productByEventAndCategory?.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.vote;
    },
    0
  );
  const RedTotalQrVote = RedTotal?.productByEventAndCategory?.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.qrVote;
    },
    0
  );
  const Green = GreenTotal?.productByEventAndCategory?.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.vote;
    },
    0
  );
  const GreenTotalQrVote = GreenTotal?.productByEventAndCategory?.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.qrVote;
    },
    0
  );
  const Blue = BlueTotal?.productByEventAndCategory?.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.vote;
    },
    0
  );
  const BlueTotalQrVote = BlueTotal?.productByEventAndCategory?.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.qrVote;
    },
    0
  );

  const percentRed = Math.round(
    ((Red + RedTotalQrVote) /
      (Red +
        RedTotalQrVote +
        Green +
        GreenTotalQrVote +
        Blue +
        BlueTotalQrVote)) *
      100
  );

  const percentGreen = Math.round(
    ((Green + GreenTotalQrVote) /
      (Red +
        RedTotalQrVote +
        Green +
        GreenTotalQrVote +
        Blue +
        BlueTotalQrVote)) *
      100
  );
  const percentBlue = Math.round(
    ((Blue + BlueTotalQrVote) /
      (Red +
        RedTotalQrVote +
        Green +
        GreenTotalQrVote +
        Blue +
        BlueTotalQrVote)) *
      100
  );

  const data = {
    labels: [titleNameRed, titleNameGreen, titleNameBlue],
    datasets: [
      {
        data: [percentRed, percentGreen, percentBlue],
        backgroundColor: [
          categories_colors[1].hex_secondary,
          categories_colors[0].hex_secondary,
          categories_colors[2].hex_secondary,
        ],
        hoverBackgroundColor: [
          categories_colors[1].hex_primary,
          categories_colors[0].hex_primary,
          categories_colors[2].hex_primary,
        ],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: false,
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        boxWidth: 30,
        fullWidth: true,
      },
    },
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
        font: {
          size: 16,
        },
        formatter: function (value, context) {
          let arr = [
            Red + RedTotalQrVote,
            Green + GreenTotalQrVote,
            Blue + BlueTotalQrVote,
          ];
          const totalVoted = arr[context.dataIndex];

          let TotalVoted = `投票数 ${totalVoted} `;
          return `${TotalVoted} (${Math.round(value)}%)`;
        },
      },
    },
    tooltips: {
      enabled: false,
      callbacks: {
        label: function (tooltipItem, data) {
          try {
            let label = ' ' + data.labels[tooltipItem.index] || '';

            if (label) {
              label += ': ';
            }

            const sum = data.datasets[0].data.reduce(
              (accumulator, curValue) => {
                return accumulator + curValue;
              }
            );
            const value =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            let arr = [
              Red + RedTotalQrVote,
              Green + GreenTotalQrVote,
              Blue + BlueTotalQrVote,
            ];
            const totalVoted = arr[tooltipItem.index];

            let TotalVoted = `投票数 ${totalVoted} (${
              Math.round(Number((value / sum) * 100)) + '%'
            })`;
            return TotalVoted;
          } catch (error) {
            console.log(error);
          }
        },
      },
    },
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0px 30px 0px 30px',
        }}
      >
        <div
          style={{
            fontSize: 30,
            fontWeight: 600,
          }}
        >
          いいね！投票：カテゴリー別 投票比率
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '25px',
              height: '25px',
              marginRight: '5px',
              marginLeft: '5px',
              backgroundColor: categories_colors[1].hex_secondary,
            }}
          />
          {titleNameRed}
          <div
            style={{
              width: '25px',
              height: '25px',
              marginRight: '5px',
              marginLeft: '5px',
              backgroundColor: categories_colors[0].hex_secondary,
            }}
          />
          {titleNameGreen}
          <div
            style={{
              width: '25px',
              height: '25px',
              marginRight: '5px',
              marginLeft: '5px',
              backgroundColor: categories_colors[2].hex_secondary,
            }}
          />
          {titleNameBlue}
        </div>
      </div>
      <div
        style={{
          fontFamily: 'sans-serif',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '40px',
        }}
      >
        {deviceType === 'desktop' ? (
          <Pie
            data={data}
            options={options}
            height={0.5 * window.innerHeight}
            width={0.6 * window.innerHeight}
          />
        ) : (
          <Pie data={data} options={options} height={300} width={400} />
        )}
      </div>
    </div>
  );
};

export default TotoPieChart;
