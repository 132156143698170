import React, { useState, useEffect, createContext } from "react";

import { Button, message, Tag, Modal, Input } from "antd";
import {} from "./Thanks.style";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import * as queryString from "query-string";
import { useHistory } from "react-router-dom";
import bg_pc from "../../../image/bg_pc.png";
import bg_mb from "../../../image/bg_mobile.png";
// import bg_mb from "../../../image/bg_mb.svg";
import { CUSTOMER_COMMENT } from "../../../graphql/MUTATION/customer";
import { useMutation } from "@apollo/react-hooks";
import { Container } from "./Thanks.style";
import $ from "jquery";

const { confirm } = Modal;
const { TextArea } = Input;

const Thanks = () => {
  const [voteDone, setVoteDone] = useState(false);
  const [createComment] = useMutation(CUSTOMER_COMMENT);
  const parameters = queryString.parse(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const { eventId } = parameters;
  const history = useHistory();

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  const getScreenType = () => {
    if (window.innerHeight > window.innerWidth) {
      return "vertical";
    }
    return "horizontal";
  };

  const DeviceType = getDeviceType();
  const ScreenType = getScreenType();

  const confirmVote = () => {
    let guestInfo = JSON.parse(localStorage.getItem("guestInfo"));
    createComment({
      variables: {
        commentEventInput: {
          eventId: eventId,
          customerId: guestInfo?.id,
          comment: comment,
        },
      },
    })
      .then((_) => {
        message.success("引き替え済み。有難う御座いました。");
        setIsLoading(false);
        localStorage.setItem("comment", comment);
        localStorage.setItem("confirmVoted", true);
        setVoteDone(true);
      })
      .catch((error) => {
        message.error("コメントのエラーが発生しました。");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    $("body").css("overflow", "hidden");
    if (localStorage.getItem("confirmVoted")) {
      setVoteDone(true);
    }
    if (localStorage.getItem("comment")) {
      setComment(localStorage.getItem("comment"));
    } else {
      $("#comment").focus();
    }
    if (
      !localStorage.getItem("eventId") ||
      localStorage.getItem("eventId") !== eventId
    ) {
      history.push({
        pathname: "/event",
        search: `?eventId=${eventId}`,
      });
    }
  }, []);

  const onCommentChange = (e) => {
    setComment(e.target.value);
  };

  return (
    <>
      <Container
        style={{
          backgroundImage:
            (ScreenType === "horizontal" && DeviceType === "mobile") ||
            (ScreenType === "horizontal" && DeviceType === "tablet") ||
            DeviceType === "desktop"
              ? `url(${bg_pc})`
              : `url(${bg_mb})`,
          backgroundSize:
            ScreenType === "vertical" && DeviceType === "tablet"
              ? "cover"
              : ScreenType === "vertical" && DeviceType === "mobile"
              ? "1000px"
              : "auto 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding:
            ScreenType === "horizontal" && DeviceType === "mobile"
              ? "0 100px"
              : ScreenType === "vertical" && DeviceType === "mobile"
              ? "20px"
              : ScreenType === "vertical" && DeviceType === "tablet"
              ? "0 100px"
              : "",
        }}
      >
        <div
          style={{
            // position: "relative",
            display: DeviceType !== "mobile" && "flex",
            flexDirection: DeviceType !== "mobile" && "column",
            justifyContent: DeviceType !== "mobile" && "space-between",
            height: "100%",
            padding:
              ScreenType === "vertical" &&
              (DeviceType === "mobile" || DeviceType === "tablet")
                ? "5% 0 20%"
                : "5% 0",
          }}
        >
          <div>
            <div
              style={{
                borderRadius: "10px",
                border: "10px",
                padding: "10px",
                backgroundColor: "#FA7E37",
                marginBottom: "10px",
                // position: 'absolute'
              }}
            >
              <div
                style={{
                  borderRadius: "10px",
                  border: "10px",
                  padding: "20px",
                  backgroundColor: "#fdd8c3",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  fontSize: DeviceType === "mobile" ? "16px" : "24px",
                  color: "#3E3E3E",
                  textAlign:
                    (DeviceType === "mobile" || DeviceType === "tablet") &&
                    "left",
                }}
              >
                いいねアンケートにご参加頂き誠に有難うございます。
                <br />
                出口付近の景品交換所に本画面をお見せください。
                <br />
                飲み物をお渡し致します。
              </div>
            </div>

            <div
              className="comment-container"
              style={{
                borderRadius: "10px",
                border: "10px",
                padding: "10px",
                marginBottom: "10px",
                backgroundColor: "#FA7E37",
                fontSize: DeviceType === "mobile" ? "16px" : "24px",
                // position: 'absolute'
              }}
            >
              <div
                style={{
                  fontSize: DeviceType === "mobile" ? "16px" : "24px",
                  textAlign: "left",
                  color: "white",
                  // position: 'absolute'
                }}
              >
                みらい市へのご要望、その他お気づきの点ございましたら入力ください。
              </div>
              <div
                style={{
                  borderRadius: "10px",
                  border: "10px",
                  backgroundColor: "#fdd8c3",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  fontSize: DeviceType === "mobile" ? "16px" : "24px",
                  color: "#3E3E3E",
                  textAlign:
                    (DeviceType === "mobile" || DeviceType === "tablet") &&
                    "left",
                }}
              >
                <TextArea
                  id="comment"
                  placeholder="入力…"
                  rows={4}
                  value={comment}
                  onChange={onCommentChange}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              position: DeviceType === "mobile" && "fixed",
              bottom: DeviceType === "mobile" && "10px",
              width: DeviceType === "mobile" && "80vw",
              left: DeviceType === "mobile" && "50%",
              transform: DeviceType === "mobile" && "translate(-50%, 0)",
            }}
          >
            <button
              onClick={confirmVote}
              style={{
                color: "#ffffff",
                backgroundColor: "#FA7E37",
                borderRadius: "10px",
                width: "220px",
                height: "50px",
                border: "0",
                fontSize: DeviceType === "mobile" ? "16px" : "24px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
            >
              引き替えボタン
            </button>
            <div
              style={{
                color: "#ff0000",
                fontSize: DeviceType === "mobile" ? "16px" : "24px",
                cursor: "pointer",
              }}
            >
              ※担当者が操作致しますので、
              {ScreenType === "vertical" &&
                (DeviceType === "mobile" || DeviceType === "tablet") && <br />}
              ボタンを押さないでください。
            </div>
          </div>
        </div>
      </Container>
      {voteDone && (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            opacity: "0.8",
            backgroundColor: "black",
            top: "0",
            color: "#ffffff",
            fontSize: DeviceType === "mobile" ? "48px" : "64px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "700",
          }}
        >
          引き替え済み
        </div>
      )}
    </>
  );
};

export default Thanks;
