import React, { useState, useEffect } from "react";

import { Button, message, Tag, Modal } from "antd";
import {
  ProductCardWrapper,
  ProductTitle,
  ProductDescription,
  ProductImage,
  ProductImageWrapper,
  WrapButton,
  WrapModal,
  WrapImage,
  WrapContent,
  TitleModal,
  DescriptionModal,
  MakerModal,
  CategoryModal,
  ProductImageIE,
} from "../Product/ProductCard.style";
import { LikeTwoTone } from "@ant-design/icons";
import { VOTE_PRODUCT } from "../../../graphql/MUTATION/voteProduct";
import { useMutation } from "@apollo/react-hooks";
import * as queryString from "query-string";
import "./productCard.css";

const ProductCard = ({
  title,
  urlImage,
  description,
  data,
  onProcess,
  eventId,
  color,
  maker,
  isIE,
  setVoted,
  ...props
}) => {
  const [createVoteProduct] = useMutation(VOTE_PRODUCT);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const parameters = queryString.parse(window.location.search);
  const { viewonly } = parameters;

  const showModalProduct = () => {
    setVisible(true);
  };
  const handleVoteProduct = () => {
    let guestInfo = JSON.parse(localStorage.getItem("guestInfo"));
    setIsLoading(true);
    let input = {
      eventId: eventId.id,
      productId: data.id,
      customerInput: {
        id: guestInfo?.id ? guestInfo?.id : "",
        companyName: guestInfo?.companyName ? guestInfo?.companyName : "",
        name: guestInfo?.name ? guestInfo?.name : "",
        tel: guestInfo?.tel ? guestInfo?.tel : "",
      },
    };
    console.log(
      "1111",
      eventId,
      process.env.REACT_APP_CHUBU_CUSTOMER_VOTE_EVENT
    );
    if (eventId.id !== process.env.REACT_APP_CHUBU_CUSTOMER_VOTE_EVENT) {
      delete input.customerInput;
    }
    try {
      createVoteProduct({
        variables: input,
      })
        .then((_) => {
          message.success("投票が成功しました。\n有難う御座いました。");
          localStorage.setItem(`${data.id}`, true);
          localStorage.setItem("voted", true);
          setVoted(true);
          setIsLoading(false);
          setVisible(false);
        })
        .catch((error) => {
          message.error(error?.graphQLErrors?.[0]?.message);
          setIsLoading(false);
        });
    } catch (error) {
      console.log("err" + error);
      setIsLoading(true);
    }
  };

  const hideModal = () => {
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <Modal
          title=""
          visible={true}
          onOk={hideModal}
          onCancel={hideModal}
          okText="确认"
          cancelText="取消"
          footer={false}
          width={"50vw"}
        >
          <WrapModal>
            <WrapImage urlImage={urlImage} />
            <WrapContent>
              <TitleModal>{title}</TitleModal>
              <DescriptionModal>
                {description
                  ? description.length > 100
                    ? description.substring(0, 100)
                    : description
                  : " *no description !"}
              </DescriptionModal>
              <MakerModal>メーカー: {maker}</MakerModal>
              <CategoryModal>
                <Tag
                  color={
                    color === "RED"
                      ? "red"
                      : color === "GREEN"
                      ? "green"
                      : "blue"
                  }
                >
                  {eventId.categoryNames[color]}
                </Tag>
              </CategoryModal>
              {onProcess ? (
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "15px",
                    marginTop: "20px",
                    marginLeft: "10px",
                    cursor:
                      viewonly || !!localStorage.getItem(data.id)
                        ? "default"
                        : "pointer",
                    // width: '95%',
                    background:
                      viewonly || !!localStorage.getItem(data.id)
                        ? "#BFBFBF"
                        : "yellow",
                    fontWeight: "bold",
                    color:
                      viewonly || !!localStorage.getItem(data.id)
                        ? "#000000"
                        : "red",
                  }}
                  disabled={viewonly || !!localStorage.getItem(data.id)}
                  onClick={() => {
                    handleVoteProduct();
                  }}
                  loading={isLoading}
                >
                  <div>
                    {viewonly || !!localStorage.getItem(data.id) ? (
                      ""
                    ) : (
                      <LikeTwoTone twoToneColor="#eb2f96" />
                    )}

                    {viewonly || !!localStorage.getItem(data.id)
                      ? "投票済"
                      : "いいね!投票"}
                  </div>
                </Button>
              ) : (
                <div style={{ marginLeft: 10, color: "red" }}>
                  イベントを実施しておりません。
                </div>
              )}
            </WrapContent>
          </WrapModal>
        </Modal>
      )}
      <ProductCardWrapper
        color={color}
        onClick={() => {
          showModalProduct();
        }}
      >
        <ProductImageWrapper>
          {isIE ? (
            <ProductImageIE urlImage={urlImage} />
          ) : (
            <ProductImage urlImage={urlImage} />
          )}
        </ProductImageWrapper>
        <ProductTitle>{title}</ProductTitle>
        <ProductDescription>
          {description ? description : " *no description !"}
        </ProductDescription>
        <WrapButton></WrapButton>
      </ProductCardWrapper>
    </>
  );
};

export default ProductCard;
