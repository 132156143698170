import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import StatisticsOngoing from './components/StatisticsOngoing/StatisticsOngoing';
import MainContent from './pages/voting/index';
import VotingScreen from './pages/voting/voting';
import VotingMakerScreen from './pages/voting/votingMaker';
import Thanks from './components/body/Thanks/Thanks';
import GiftExchange from './components/body/GiftExchange/GiftExchange';

function App() {
  return (
    <Router>
      <div className='App'>
        <Switch>
          <Route exact path={`/event`} component={MainContent} />
          <Route exact path={`/thanks`} component={Thanks} />
          <Route exact path={`/gift-exchange`} component={GiftExchange} />
          <Route path='/voting' component={VotingScreen} />
          <Route path='/voting-maker' component={VotingMakerScreen} />
          <Route path={'/statistics'}>
            <Suspense fallback={''}>
              <StatisticsOngoing />
            </Suspense>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
