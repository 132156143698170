import React from "react";
// import './productCard.css';
import { Tooltip } from "antd";
import styled from "styled-components";

export const ProductCardWrapper = (props) => {
  return (
    <Tooltip placement="rightTop" title="クリックして詳細を見る">
      <div
        className={
          props.color === "RED"
            ? "styleCardRed"
            : props.color === "GREEN"
            ? "styleCardGreen"
            : "styleCard"
        }
        onClick={props.onClick}
        style={{
          // boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          border: "1px solid #efefef",

          transition: "0.3s",
          background: "#fff",
          float: "left",
          height: "100%",
          width: "100%",
          cursor: "pointer",
        }}
      >
        {props.children}
      </div>
    </Tooltip>
  );
};

export const Container = styled.div`
  width: 100vw;
  height: ${window.innerHeight}px;
  min-height: 700px;
  background-size: 1000px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
