import gql from "graphql-tag";

const QUERY_EVENTS_BY_ID = gql`
  query eventById($id: String!) {
    eventById: eventByIdNoAuth(id: $id) {
      id
      name
      categoryNames {
        RED
        BLUE
        GREEN
      }
      onProcess
    }
  }
`;

const QUERY_PRODUCT_IN_EVENT_ACTIVE = gql`
  query productInEventActive($eventId: String!, $productId: String!) {
    productInEventActive: productInEventActiveNoAuth(
      eventId: $eventId
      productId: $productId
    ) {
      name
      id
      description
      image
      category {
        code
      }
      maker {
        name
      }
    }
  }
`;

const QUERY_PRODUCT_MAKER_IN_EVENT_ACTIVE = gql`
  query productInEventActive($eventId: String!, $makerId: String!) {
    productInEventActive: productMakerByEventAndCategory(
      eventId: $eventId
      makerId: $makerId
    ) {
      name
      graphDisplayName
      id
      description
      image
      category {
        code
      }
      maker {
        name
      }
      position
    }
  }
`;

export {
  QUERY_EVENTS_BY_ID,
  QUERY_PRODUCT_IN_EVENT_ACTIVE,
  QUERY_PRODUCT_MAKER_IN_EVENT_ACTIVE,
};
