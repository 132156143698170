import gql from 'graphql-tag';

const QUERY_LIST_EVENTS = gql`
  query listEvent {
    listEvent:listEventNoAuth {
      id
      name
      categoryNames {
        RED
        GREEN
        BLUE
      }
      onProcess
    }
  }
`;
const QUERY_PRODUCTS_BY_EVENT_ID = gql`
  query productByEventId($eventId: String!) {
    products: productByEventId(eventId: $eventId) {
      id
      name
      position
      vote
      category {
        code
      }
      maker {
        id
        name
      }
    }
  }
`;

const QUERY_EVENT_BY_ID = gql`
query eventById($id: String!) {
  eventById:eventByIdNoAuth(id: $id) {
    id
    name
    description
    startTime
    endTime
    numOfMaker
  }
}
`

const SUBSCRIPTION_VOTED = gql`
subscription voted{
	voted {
    product {
      name
      category {
        code
      }
    }
  }
}`
export {
  QUERY_PRODUCTS_BY_EVENT_ID,
  QUERY_LIST_EVENTS,
  QUERY_EVENT_BY_ID,
  SUBSCRIPTION_VOTED
};