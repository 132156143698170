import { LeftCircleOutlined, LikeTwoTone } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, message, Result, Spin } from "antd";
import * as queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import { VOTE_PRODUCT } from "../../graphql/MUTATION/voteProduct";
import { QUERY_PRODUCT_MAKER_IN_EVENT_ACTIVE } from "../../graphql/QUERY/event";
import { VoteCard } from "./voting.style";

const Page404 = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  );
};
const VotingScreen = () => {
  const { eventId, qrCode, makerId } = queryString.parse(
    window.location.search
  );
  var isTrue = qrCode === "true";
  const history = useHistory();
  const { data: productData, error } = useQuery(
    QUERY_PRODUCT_MAKER_IN_EVENT_ACTIVE,
    {
      variables: { makerId, eventId },
      fetchPolicy: "network-only",
    }
  );

  // eslint-disable-next-line no-undef
  const [createVoteProduct] = useMutation(VOTE_PRODUCT);
  const [spin, setSpin] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
  });

  if (!makerId || !eventId) return <Page404 />;

  const handleVoting = (item) => {
    setIsLoading(true);
    try {
      createVoteProduct({
        variables: {
          eventId,
          productId: item.id,
          qrVote: isTrue,
        },
      })
        .then((_) => {
          message.success("投票が成功しました。");
          localStorage.setItem(`${eventId.concat(item.id)}`, true);
          localStorage.setItem(item.id, true);
          setIsLoading(false);
        })
        .catch((error) => {
          message.error(error?.graphQLErrors?.[0]?.message);
          setIsLoading(false);
        });
    } catch (error) {
      console.log("err" + error);
      setIsLoading(true);
    }
  };

  if (error || !productData?.productInEventActive) {
    setTimeout(() => {
      setSpin(false);
    }, 7000);
    return (
      <div style={{ marginTop: "40vh" }}>
        {spin ? (
          <Spin />
        ) : (
          <div>
            <Result title={"イベントを実施しておりません。"} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        height: window.innerHeight,
        overflowY: "auto",
        paddingBottom: 30,
        margin: 5,
      }}
    >
      <div
        style={{
          textTransform: "uppercase",
          color: "#376092",
          fontWeight: "600",
          fontSize: "30px",
          fontFamily: "inherit",
          width: "100%",
          textAlign: "center",
          wordBreak: "break-all",
          padding: "10px",
        }}
      >
        {productData?.productInEventActive[0].maker.name}
      </div>
      {productData?.productInEventActive
        ?.sort((a, b) => a.position - b.position)
        ?.map((item, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "5px",
              }}
            >
              <VoteCard>
                <div className="card-title">
                  {item.graphDisplayName ? item.graphDisplayName : item.name}
                </div>
                <div
                  style={{
                    // position: "relative",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height:
                      dimensions.height > dimensions.width ? "12vh" : "40vh",
                    // minHeight: '150px'
                  }}
                >
                  <div
                    style={{
                      // position: "relative",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height:
                        dimensions.height > dimensions.width ? "12vh" : "40vh",
                      width: "60%",
                      // minHeight: '150px'
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "90%",
                        maxHeight: "100%",
                        display: "inline-block",
                      }}
                      alt="example"
                      src={item.image}
                    />
                  </div>
                  <div
                    style={{
                      width: "40%",
                      height: "80%",
                      margin: "auto",
                      marginRight: "10px",
                      // height: "65vh",
                    }}
                  >
                    <Button
                      onClick={() => handleVoting(item)}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "17px",
                        alignItems: "center",
                        height: localStorage.getItem(eventId.concat(item.id))
                          ? "100%"
                          : "100%",
                        // marginTop: "10px",
                        width: "100%",
                        background: localStorage.getItem(
                          eventId.concat(item.id)
                        )
                          ? "#BFBFBF"
                          : "yellow",
                        fontWeight: "bold",
                        color: localStorage.getItem(eventId.concat(item.id))
                          ? "#000000"
                          : "red",
                      }}
                      disabled={Boolean(
                        localStorage.getItem(eventId.concat(item.id))
                      )}
                      loading={isLoading}
                    >
                      <div>
                        {localStorage.getItem(eventId.concat(item.id)) ? (
                          ""
                        ) : (
                          <LikeTwoTone twoToneColor="#eb2f96" />
                        )}
                        {localStorage.getItem(eventId.concat(item.id))
                          ? "投票済"
                          : "いいね!投票"}
                      </div>
                    </Button>
                  </div>
                </div>
              </VoteCard>
            </div>
          );
        })}
    </div>
  );
};

export default VotingScreen;
