import React from 'react';

const Wrapper = ({ children }) => {
  return (
    <div
      style={{
        fontFamily: 'sans-serif',
        textAlign: 'center',
        width: '100%',
      }}
    >
      {children}
    </div>
  );
};

const WrapperNameCategory = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 30px 0px 30px',
      }}
    >
      {children}
    </div>
  );
};

const NameCategory = ({ children }) => {
  return (
    <div
      style={{
        marginTop: 20,
        fontSize: 30,
        fontWeight: 'bold',
        color: '#000000',
      }}
    >
      {children}
    </div>
  );
};

const Label = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          width: '25px',
          height: '25px',
          marginRight: '5px',
          marginLeft: '5px',
          backgroundColor: '#5979c6',
        }}
      />
      リアル投票
      <div
        style={{
          width: '25px',
          height: '25px',
          marginRight: '5px',
          marginLeft: '5px',
          backgroundColor: props.color,
        }}
      />
      WEB投票
    </div>
  );
};

const NoResult = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '55vh',
        border: '1px solid #eaeaea',
        marginLeft: '30px',
      }}
    >
      {children}
    </div>
  );
};
export { Wrapper, WrapperNameCategory, NameCategory, Label, NoResult };
