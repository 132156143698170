import gql from "graphql-tag";

const VOTE_PRODUCT = gql`
  mutation (
    $qrVote: Boolean
    $eventId: String!
    $productId: String!
    $customerInput: CustomerInput
  ) {
    vote(
      eventId: $eventId
      productId: $productId
      qrVote: $qrVote
      customerInput: $customerInput
    )
  }
`;

export { VOTE_PRODUCT };
