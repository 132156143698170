/* eslint-disable */
import { useQuery, useSubscription } from '@apollo/react-hooks';
import { Button, Result, Row, Col, Spin } from 'antd';
import * as queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  QUERY_PRODUCTS_BY_EVENT_ID,
  QUERY_PRODUCT_BY_EVENT_AND_CATEGORY,
} from '../../graphql/QUERY/product';
import BlueBarChart from './BlueChart';
import GreenBarChart from './GreenChart';
import './index.css';
import RedBarChart from './RedChart';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  QUERY_EVENT_BY_ID,
  QUERY_LIST_EVENTS,
  SUBSCRIPTION_VOTED,
} from './statistics.graphql';
import TotalBarChart from './TotalBarChart';
import TotoPieChart from './TotalDataChart';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';
import {
  EventName,
  StartTime,
  EndTime,
  Description,
  MakerJoin,
} from '../StatisticsOngoing/Statistics.style';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const StatisticsOngoing = () => {
  const { eventId, type } = queryString.parse(window.location.search);
  const history = useHistory();
  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  };

  var isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const DeviceType = getDeviceType();

  const { data, error, loading, refetch } = useQuery(QUERY_LIST_EVENTS, {
    fetchPolicy: 'no-cache',
  });
  const {
    data: eventData,
    error: errorData,
    loading: loadingData,
    refetch: refetchData,
  } = useQuery(QUERY_EVENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id: eventId || '',
    },
  });

  const [eventChosen, setEventChosen] = useState(data?.listEvent[0]?.id || '');
  const onProcessing = useSelector((state) => state.loading.onProcessing);
  const dispatch = useDispatch();
  const [titleNames, setTitleNames] = useState(null);

  //fetch product data
  const {
    loading: subLoading,
    error: subError,
    data: subData,
  } = useSubscription(SUBSCRIPTION_VOTED);
  const {
    data: productData,
    error: productError,
    loading: productLoading,
    refetch: productRefetch,
  } = useQuery(QUERY_PRODUCTS_BY_EVENT_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: eventChosen,
    },
  });

  const {
    data: productByEventAndCategoryRed,
    refetch: refetchProductByEventAndCategoryRed,
  } = useQuery(QUERY_PRODUCT_BY_EVENT_AND_CATEGORY, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: eventChosen || 'no-event',
      categoryCode: 'RED',
    },
  });
  const {
    data: productByEventAndCategoryGreen,
    refetch: refetchProductByEventAndCategoryGreen,
  } = useQuery(QUERY_PRODUCT_BY_EVENT_AND_CATEGORY, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: eventChosen || 'no-event',
      categoryCode: 'GREEN',
    },
  });

  const {
    data: productByEventAndCategoryBlue,
    refetch: refetchProductByEventAndCategoryBlue,
  } = useQuery(QUERY_PRODUCT_BY_EVENT_AND_CATEGORY, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: eventChosen || 'no-event',
      categoryCode: 'BLUE',
    },
  });

  useEffect(() => {
    setEventChosen(eventId);
  }, []);

  // useEffect(() => {
  //   if (type) {
  //     switch (type?.toLowerCase()) {
  //       case 'red':
  //         document.title = "空調"
  //         break;
  //       case 'green':
  //         document.title = "住設"
  //         break;
  //       case 'blue':
  //         document.title = "管材"
  //         break;
  //       default:
  //         document.title = "総合"
  //         break;
  //     }
  //   }
  // }, [type])

  useEffect(() => {
    if (data) {
      const categoryNames = data?.listEvent?.find(
        (item) => item.id === eventChosen
      );
      setTitleNames(categoryNames);
    }
  }, [data]);
  useEffect(() => {
    productRefetch({
      eventId: eventChosen,
    });
    const category = subData?.voted?.product?.category?.code || 'RED';
    switch (category) {
      case 'RED':
        refetchProductByEventAndCategoryRed();
        break;

      case 'GREEN':
        refetchProductByEventAndCategoryGreen();
        break;

      case 'BLUE':
        refetchProductByEventAndCategoryBlue();
        break;

      default:
        break;
    }
  }, [eventChosen, subData]);

  if (errorData) {
    return (
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
      />
    );
  } else {
    return (
      <Spin
        style={{ marginTop: window.innerHeight / 5 }}
        indicator={antIcon}
        spinning={loadingData}
      >
        <div
          style={{
            overflowY: 'auto',
            height: '100vh',
            overflowX: 'hidden',
          }}
        >
          {type === 'all' && (
            <Row justify='center' gutter={8} span={24}>
              <Col xl={4} span={8}>
                <div
                  style={{
                    display: 'flex',
                    margin: '20px',
                  }}
                >
                  <Button
                    className='buttonStyle'
                    onClick={() => {
                      history.push(`/event?eventId=${eventChosen}`);
                    }}
                  >
                    <ArrowLeftOutlined />
                    いいね！投票ページへ
                  </Button>
                </div>
              </Col>

              <Col
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  display: isIE ? 'table-header-group' : 'grid',
                  justifyContent: 'center',
                }}
                xl={16}
                span={8}
              >
                <EventName>
                  {eventData?.eventById?.name || 'EVENT_NAME'}
                </EventName>
                <StartTime>
                  {dayjs(parseInt(eventData?.eventById?.startTime)).format(
                    'YYYY.MM.DD h:mm A'
                  ) || '20.11.2020'}
                </StartTime>
                <EndTime>
                  {dayjs(parseInt(eventData?.eventById?.endTime)).format(
                    'YYYY.MM.DD h:mm A'
                  ) || '20.11.2020'}
                </EndTime>
                <Description>
                  {eventData?.eventById?.description || 'no description'}
                </Description>
                <MakerJoin> {eventData?.eventById?.numOfMaker || 0}</MakerJoin>
              </Col>
              <Col xl={4} span={8}></Col>
            </Row>
          )}

          <div
            style={{
              paddingLeft: '20px',
              paddingRight: '20px',
              width: '100%',
              height: '100%',
              display: type === 'all' ? '' : 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {(type === 'all' || type === 'total') && (
              <TotalBarChart data={productData} />
            )}
            <br />
            {(type === 'all' || type === 'red') && (
              <RedBarChart
                titleName={titleNames?.categoryNames?.RED}
                Red={productByEventAndCategoryRed}
              />
            )}
            <br />
            {(type === 'all' || type === 'green') && (
              <GreenBarChart
                titleName={titleNames?.categoryNames?.GREEN}
                Green={productByEventAndCategoryGreen}
              />
            )}
            <br />
            {(type === 'all' || type === 'blue') && (
              <BlueBarChart
                titleName={titleNames?.categoryNames?.BLUE}
                Blue={productByEventAndCategoryBlue}
              />
            )}
            <br />
            {(type === 'all' || type === 'pie') && (
              <TotoPieChart
                titleNameRed={titleNames?.categoryNames?.RED}
                titleNameGreen={titleNames?.categoryNames?.GREEN}
                titleNameBlue={titleNames?.categoryNames?.BLUE}
                deviceType={DeviceType}
                RedTotal={productByEventAndCategoryRed}
                GreenTotal={productByEventAndCategoryGreen}
                BlueTotal={productByEventAndCategoryBlue}
              />
            )}
            <br />
          </div>
        </div>
      </Spin>
    );
  }
};

export default StatisticsOngoing;
